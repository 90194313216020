import React from "react"
import PropTypes from "prop-types"
import InViewMonitor from "react-inview-monitor"
import { injectGlobal } from "react-emotion"

import { colors, transition } from "../../utils/presets"

injectGlobal`
.bg-stars-animated {
  position: absolute;
  height: 100%;
  width: 100%;
}
.star--accent .starContent {
  color: ${colors.accent};
}
.star--bright .starContent {
  color: ${colors.ui.bright};
}
.star--light.starContent {
   color: ${colors.ui.light};
}
.star--gatsby .starContent {
  color: ${colors.lilac};
}
.star--white .starContent {
  color: ${colors.white};
}
`

class Star extends React.Component {
  render() {
    const { scale, color, x, y } = this.props

    return (
      <svg className={`star star--${color}`} x={x} y={y}>
        <g
          className="starContent"
          transform={`scale(${scale})`}
          css={{
            color: colors.purple[`400`],
          }}
        >
          <g className="starInner">
            <path
              fill="url(#star-a)"
              fillRule="evenodd"
              className="starBackground"
              css={{
                fill: `currentColor`,
                fillOpacity: 0.6,
                // stroke: `currentColor`,
                // strokeOpacity: .3,
              }}
              d="M13.136,5.728 C11.5573333,5.728 10.272,4.44266667 10.272,2.864 C10.272,1.29066667 9.248,0 8,0 C6.752,0 5.728,1.29066667 5.728,2.864 C5.728,4.44266667 4.44266667,5.728 2.864,5.728 C1.29066667,5.728 0,6.752 0,8 C0,9.248 1.29066667,10.272 2.864,10.272 C4.44266667,10.272 5.728,11.5573333 5.728,13.136 C5.728,14.7093333 6.752,16 8,16 C9.248,16 10.272,14.7093333 10.272,13.136 C10.272,11.5573333 11.5573333,10.272 13.136,10.272 C14.7093333,10.272 16,9.248 16,8 C16,6.752 14.7093333,5.728 13.136,5.728 Z"
            />
          </g>
        </g>
      </svg>
    )
  }
}

Star.propTypes = {
  scale: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  x: PropTypes.string,
  y: PropTypes.string,
}

class Stars extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      animate: false,
    }
  }

  UNSAFE_componentWillReceiveProps() {
    this.setState({ animate: true }, () => {
      // triggers a browser reflow ensureing the animation happens
      // even if it's on the same tick
      // @see https://github.com/facebook/react/issues/7142#issuecomment-355435717
      // @see https://codesandbox.io/s/2496rpxlkj
      const offsetHeight = this.domNode.offsetHeight

      this.setState({ animate: true })

      return offsetHeight
    })
  }

  render() {
    const { containerStyles, stars } = this.props
    const { animate } = this.state
    const animateClass = animate ? `stars starInner--animated` : `stars`

    const transitionDelayStyles = stars.map((star, i) => {
      return {
        [`&.starInner--animated .star:nth-child(${i + 1}) .starInner`]: {
          transitionDelay: `${0.25 + parseInt(i, 10) * 0.025}s`,
        },
      }
    })

    const mergedStyles = transitionDelayStyles.reduce(function(acc, x) {
      for (const key in x) {
        if (key) {
          acc[key] = x[key]
        }
      }
      return acc
    }, {})

    const svgStyles = {
      overflow: `visible`,
      position: `absolute`,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: -1,
      width: `100%`,
      ...containerStyles,
      "& .starInner": {
        opacity: 0,
        transformOrigin: `0 100%`,
        transitionDuration: transition.speed.snail,
        transitionTimingFunction: transition.curve.default,
        transform: `scale(0) translate(0,-100px)`,
      },
      "&.starInner--animated .starInner": {
        opacity: 1,
        transform: `scale(1) translate(0,0)`,
      },
      ...mergedStyles,
    }

    return (
      <svg
        className={animateClass}
        css={{ ...svgStyles }}
        ref={r => (this.domNode = r)}
      >
        {stars.map((star, i) => (
          <Star
            x={star.x}
            y={star.y}
            color={star.color}
            scale={star.scale}
            key={i}
            opacity={star.opacity}
            index={i}
          />
        ))}
      </svg>
    )
  }
}

Stars.propTypes = {
  containerStyles: PropTypes.object,
  stars: PropTypes.array,
}

const AnimatedStarsBackground = ({ s, containerCSS }) => {
  const stars = s || [
    {
      x: `80%`,
      y: `15%`,
      color: `gatsby`,
      scale: 0.5,
      opacity: 1,
    },
    {
      x: `12%`,
      y: `58%`,
      color: `gatsby`,
      scale: 0.4,
      opacity: 1,
    },
    {
      x: `5%`,
      y: `35%`,
      color: `gatsby`,
      scale: 0.5,
      opacity: 1,
    },
    {
      x: `10%`,
      y: `70%`,
      color: `gatsby`,
      scale: 0.25,
      opacity: 1,
    },
    {
      x: `15%`,
      y: `52%`,
      color: `bright`,
      scale: 0.35,
      opacity: 0.8,
    },
    {
      x: `25%`,
      y: `35%`,
      speed: -2,
      color: `accent`,
      scale: 0.25,
      opacity: 1,
    },
    {
      x: `47%`,
      y: `42%`,
      color: `bright`,
      scale: 0.5,
      opacity: 1,
    },
    {
      x: `58%`,
      y: `52%`,
      color: `bright`,
      scale: 0.3,
      opacity: 1,
    },
    {
      x: `10%`,
      y: `50%`,
      color: `bright`,
      scale: 0.4,
      opacity: 1,
    },
    {
      x: `20%`,
      y: `30%`,
      color: `accent`,
      scale: 0.25,
      opacity: 1,
    },
    {
      x: `40%`,
      y: `30%`,
      speed: -2,
      color: `bright`,
      scale: 0.5,
      opacity: 1,
    },
    {
      x: `85%`,
      y: `20%`,
      color: `gatsby`,
      scale: 0.25,
      opacity: 1,
    },
    {
      x: `70%`,
      y: `20%`,
      speed: -2,
      color: `bright`,
      scale: 0.35,
      opacity: 1,
    },
    {
      x: `90%`,
      y: `10%`,
      color: `accent`,
      scale: 0.35,
      opacity: 1,
    },
    {
      x: `75%`,
      y: `10%`,
      color: `bright`,
      scale: 0.25,
      opacity: 1,
    },
    {
      x: `75%`,
      y: `25%`,
      color: `bright`,
      scale: 0.45,
      opacity: 1,
    },
    {
      x: `51%`,
      y: `82%`,
      color: `bright`,
      scale: 0.35,
      opacity: 1,
    },
    {
      x: `21%`,
      y: `36%`,
      color: `accent`,
      scale: 0.3,
      opacity: 1,
    },
    {
      x: `28%`,
      y: `5%`,
      color: `bright`,
      scale: 0.2,
      opacity: 0.5,
    },
    {
      x: `32%`,
      y: `8%`,
      speed: -2,
      color: `gatsby`,
      scale: 0.15,
      opacity: 1,
    },
    {
      x: `65%`,
      y: `60%`,
      color: `accent`,
      scale: 0.25,
      opacity: 0.25,
    },
    {
      x: `70%`,
      y: `49%`,
      speed: -2,
      color: `bright`,
      scale: 0.35,
      opacity: 0.5,
    },
    {
      x: `80%`,
      y: `75%`,
      color: `accent`,
      scale: 0.25,
      opacity: 0.35,
    },
    {
      x: `72%`,
      y: `35%`,
      color: `bright`,
      scale: 0.5,
      opacity: 0.3,
    },
    {
      x: `75%`,
      y: `47.5%`,
      color: `accent`,
      scale: 0.5,
      opacity: 0.45,
    },
    {
      x: `58%`,
      y: `40%`,
      color: `accent`,
      scale: 0.5,
      opacity: 0.25,
    },
    {
      x: `60%`,
      y: `77%`,
      color: `gatsby`,
      scale: 0.25,
      opacity: 0.15,
    },
    {
      x: `50%`,
      y: `50%`,
      color: `bright`,
      scale: 0.25,
    },
    {
      x: `40%`,
      y: `58%`,
      color: `bright`,
      scale: 0.45,
      opacity: 0.5,
    },
    {
      x: `25%`,
      y: `72%`,
      color: `accent`,
      scale: 0.35,
      opacity: 0.1,
    },
    {
      x: `17.5%`,
      y: `85%`,
      color: `gatsby`,
      scale: 0.25,
      opacity: 0.15,
    },
  ]

  const containerStyles = containerCSS || {
    height: `100%`,
    marginBottom: `-2.5vh`,
    marginTop: `-40vh`,
    width: `100%`,
    pointerEvents: `none`,
    userSelect: `none`,
  }

  return (
    <InViewMonitor
      classNameNotInView="bg-stars-animated"
      classNameInView="bg-stars-animated"
      childPropsInView={{ isAnimated: true }}
      toggleChildPropsOnInView={true}
      intoViewMargin="-40%"
    >
      <Stars
        containerStyles={containerStyles}
        stars={stars}
        isAnimated={false}
      />
    </InViewMonitor>
  )
}

AnimatedStarsBackground.propTypes = {
  s: PropTypes.array,
  containerCSS: PropTypes.object,
}

export default AnimatedStarsBackground
