import { graphql } from "gatsby"
import React from "react"
import PropTypes from "prop-types"
import Plx from "react-plx"
import { keyframes } from "react-emotion"
import windowSize from "react-window-size"

import Section from "./section"
import Stars from "./stars"
import Layout from "../../layouts"
import Container from "../../components/container"
import HeroContainer from "../../components/containers/hero"
import SEO from "../../components/seo"
import Metaball from "../../components/metaball"
import Header from "../../components/header"
import Footer from "../../components/footer"
import Title from "../../components/hero/title"
import Subtitle from "../../components/hero/subtitle"
import { mediaQueries, colors, space } from "../../utils/presets"
import { rhythm } from "../../utils/typography"
import {
  titleStyles,
  defaultPadding,
  guidePaddingBottom,
} from "../../utils/styles"
import LazySVGImg from "../../components/lazy-svg"

const floatXAxis = keyframes({
  "50%": {
    transform: `translateX(-1rem)`,
  },
})

const floatYAxis = keyframes({
  "50%": {
    transform: `translateY(1.2rem)`,
  },
})

const butlerStyles = {
  animation: `${floatYAxis} 8s ease-in-out infinite`,
  zIndex: 10,
  width: 120,
  opacity: 0.35,
  position: `absolute`,
  top: `14vh`,
  left: `auto`,
  right: `10vw`,
  [mediaQueries.phablet]: {
    opacity: 1,
    width: 140,
    top: `5vh`,
    left: `auto`,
    right: `calc(10vw - 5vh)`,
  },
  [mediaQueries.tablet]: {
    width: 160,
    top: `-4vh`,
    left: `calc(( 100px + 10vw ) * -1)`,
    right: `auto`,
  },
  [mediaQueries.desktop]: {
    right: `4vw`,
    left: `auto`,
  },
}

class GuidesListPage extends React.Component {
  render() {
    const { contentfulPage, allContentfulPage, nav } = this.props.data
    const { title, subtitle } = contentfulPage
    const clientHeight = this.props.windowHeight || 1
    const guidesSections = allContentfulPage.edges
      .map(edge => edge.node)
      .filter(
        node => node.parentPage && node.parentPage.name === `How It Works`
      )

    return (
      <Layout pathname={this.props.location.pathname}>
        <SEO contentfulPage={contentfulPage} />
        <Header navItems={nav.edges} />
        <div css={{ position: `relative`, width: `100%` }}>
          <Plx
            // animateWhenNotInViewport
            parallaxData={[
              {
                duration: clientHeight * 1.5,
                offset: -clientHeight,
                properties: [
                  {
                    endValue: 200,
                    property: `translateY`,
                    startValue: 0,
                  },
                ],
                start: `self`,
              },
            ]}
            css={{ position: `relative` }}
          >
            <Metaball />
          </Plx>
          <Plx
            animateWhenNotInViewport
            parallaxData={[
              {
                duration: clientHeight * 1.5,
                offset: clientHeight,
                properties: [
                  {
                    endValue: 500,
                    property: `translateY`,
                    startValue: 0,
                  },
                  {
                    endValue: 0,
                    property: `opacity`,
                    startValue: 1,
                  },
                ],
                start: `self`,
              },
            ]}
            css={{ position: `relative` }}
          >
            <Stars />
          </Plx>
          <HeroContainer>
            <Container
              customStyles={{ [mediaQueries.tablet]: { ...defaultPadding } }}
            >
              <div
                css={{
                  animation: `${floatXAxis} 6s ease-in-out infinite`,
                  position: `relative`,
                }}
              >
                <LazySVGImg
                  src="guides-astronaut-butler.svg"
                  alt={``}
                  css={butlerStyles}
                />
              </div>
              <div
                css={{
                  position: `relative`,
                  zIndex: 100,
                  width: `100%`,
                  // make room for the butler
                  [mediaQueries.phablet]: {
                    paddingRight: 160,
                  },
                  [mediaQueries.tablet]: {
                    paddingRight: 0,
                  },
                }}
              >
                <Plx
                  animateWhenNotInViewport
                  parallaxData={[
                    {
                      duration: clientHeight * 1.5,
                      offset: -clientHeight,
                      properties: [
                        {
                          endValue: -50,
                          property: `translateY`,
                          startValue: 0,
                        },
                      ],
                      start: `self`,
                    },
                  ]}
                >
                  <Title
                    customStyles={{
                      marginBottom: 0,
                      color: colors.gatsby,
                    }}
                  >
                    {title}
                  </Title>
                  <Subtitle
                    customStyles={{
                      ...titleStyles,
                      color: colors.gatsby,
                      marginTop: 0,
                      fontWeight: `normal`,
                      maxWidth: rhythm(17),
                    }}
                  >
                    {subtitle.subtitle}
                  </Subtitle>
                </Plx>
              </div>
            </Container>
          </HeroContainer>
          <div
            css={{
              paddingTop: space[12],
              overflow: `hidden`,
              position: `relative`,
              ...guidePaddingBottom,
            }}
          >
            {guidesSections && (
              <Container
                customStyles={{
                  [mediaQueries.tablet]: {
                    ...defaultPadding,
                  },
                }}
              >
                {guidesSections.map((page, i) => (
                  <Plx
                    animateWhenNotInViewport
                    parallaxData={[
                      {
                        duration: clientHeight * 1.5,
                        offset: -clientHeight,
                        properties: [
                          {
                            endValue: -400,
                            property: `translateY`,
                            startValue: 0,
                          },
                        ],
                        start: `self`,
                      },
                    ]}
                    key={i}
                  >
                    <Section page={page} />
                  </Plx>
                ))}
              </Container>
            )}
          </div>
        </div>
        <Footer navItems={nav.edges} />
      </Layout>
    )
  }
}

GuidesListPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  dimensions: PropTypes.object,
  windowHeight: PropTypes.number,
}

export default windowSize(GuidesListPage)

export const pageQuery = graphql`
  query ContentfulGuidesPage($id: String!) {
    ...MainNavigation
    contentfulPage(id: { eq: $id }) {
      name
      title
      subtitle {
        subtitle
      }
      ...SocialMediaImage
    }
    allContentfulPage {
      edges {
        node {
          name
          keyvisual {
            name
          }
          parentPage {
            name
            slug
          }
          sections {
            text {
              childMarkdownRemark {
                html
              }
            }
            ...FeaturesListFragment
            associatedPage {
              name
              slug
              subtitle {
                subtitle
              }
              parentPage {
                name
                slug
              }
            }
          }
        }
      }
    }
  }
`
