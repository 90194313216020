import React from "react"
import { injectGlobal } from "react-emotion"
import InViewMonitor from "react-inview-monitor"

injectGlobal`
  .vis-hidden {
    visibility: hidden;
  }

  .animated {
    -webkit-animation-duration: 0.35s;
    animation-duration: 0.35s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  @keyframes fadeInUp {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, 10%, 0);
      transform: translate3d(0, 10%, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }

  .fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
  }
`

const RevealOnScroll = ({ children }) => (
  <InViewMonitor
    classNameNotInView="vis-hidden"
    classNameInView="animated fadeInUp"
  >
    {children}
  </InViewMonitor>
)

export default RevealOnScroll
