import React from "react"
import { MdArrowForward } from "react-icons/md"

import { colors } from "../utils/presets"
import { linkStyles } from "../utils/styles"

const ReadMore = () => (
  <p
    css={{
      color: colors.lilac,
      display: `inline-block`,
      ...linkStyles,
    }}
    className="read-more"
  >
    Read more
    {` `}
    <MdArrowForward css={{ verticalAlign: `baseline`, marginLeft: `.2em` }} />
  </p>
)

export default ReadMore
