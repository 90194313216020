import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import deepmerge from "deepmerge"

import RevealOnScroll from "../../components/reveal-on-scroll"
import HeadlineSmallCaps from "../../components/headline-small-caps"
import ReadMore from "../../components/read-more"
import LazySVGImg from "../../components/lazy-svg"

import { getLink } from "../../utils/navigation"
import { rhythm } from "../../utils/typography"
import { colors, fontSizes, mediaQueries, space } from "../../utils/presets"
import { bodyCopy, titleStyles } from "../../utils/styles"

const styles = {
  container: {
    paddingTop: space[4],
    position: `relative`,
    zIndex: 1,
  },
  link: {
    textDecoration: `none`,
    "&:hover h3": {
      color: colors.gatsby,
    },
    "&:hover .read-more": {
      color: colors.gatsby,
    },
  },
  column: {
    paddingBottom: space[9],
    [mediaQueries.desktop]: {
      flex: `0 0 50%`,
      paddingRight: space[6],
      paddingTop: space[9],
      paddingBottom: space[9],
      "&:nth-child(2n+0)": {
        paddingLeft: space[6],
        paddingRight: 0,
      },
    },
  },
  keyvisual: {
    position: `absolute`,
    left: -200,
    top: 0,
    "& .SVGInline-svg": { width: 160 },
    [mediaQueries.desktop]: {
      "& .SVGInline-svg": { width: 200 },
      left: -240,
    },
  },
  titleStylesCustom: {
    fontWeight: `bold`,
    [mediaQueries.desktop]: {
      maxWidth: 480,
      marginBottom: space[9],
    },
  },
  titleStylesCustomColumn: {
    [mediaQueries.desktop]: {
      maxWidth: 360,
      fontSize: fontSizes[6],
    },
  },
  bodyCopyCustom: {
    [mediaQueries.desktop]: {
      fontSize: fontSizes[1],
      maxWidth: rhythm(16),
    },
  },
}

const Single = ({ section }) => (
  <Link to={getLink(section.associatedPage)} css={{ ...styles.link }}>
    <h3 css={deepmerge(titleStyles, styles.titleStylesCustom)}>
      {section.associatedPage.name}
    </h3>
    <div css={bodyCopy}>
      {!!section.text && (
        <div
          dangerouslySetInnerHTML={{
            __html:
              section.text.childMarkdownRemark &&
              section.text.childMarkdownRemark.html,
          }}
        />
      )}
      <ReadMore />
    </div>
  </Link>
)

Single.propTypes = {
  section: PropTypes.object.isRequired,
}

const Column = ({ section }) => (
  <div css={styles.column}>
    <Link to={getLink(section.associatedPage)} css={{ ...styles.link }}>
      <h3
        css={deepmerge.all([
          titleStyles,
          styles.titleStylesCustom,
          styles.titleStylesCustomColumn,
        ])}
      >
        {section.associatedPage.name}
      </h3>
      <div
        css={{
          ...bodyCopy,
          ...styles.bodyCopyCustom,
        }}
      >
        <div
          dangerouslySetInnerHTML={{
            __html:
              section.text &&
              section.text.childMarkdownRemark &&
              section.text.childMarkdownRemark.html,
          }}
        />
        <ReadMore />
      </div>
    </Link>
  </div>
)

Column.propTypes = {
  section: PropTypes.object.isRequired,
}

const GuidesSection = ({ page }) => {
  const { sections } = page
  let sectionsToRender = []

  // flatten section.featuresList, noob version
  sections &&
    sections.forEach(section => {
      !section.featuresList
        ? sectionsToRender.push(section)
        : section.featuresList.forEach(featuresList => {
            sectionsToRender.push(featuresList)
          })
    })

  // filter sections that do not have an associated page
  // or link to "Support" (moar noob)
  sectionsToRender = sectionsToRender.filter(
    section =>
      section.associatedPage && section.associatedPage.slug !== `support`
  )

  if (sectionsToRender.length === 0) {
    return null
  }

  return (
    <RevealOnScroll>
      <div css={styles.container}>
        {page.keyvisual && (
          <div css={styles.keyvisual}>
            <LazySVGImg src={`${page.keyvisual.name}.svg`} />
          </div>
        )}
        <HeadlineSmallCaps color={colors.gatsby}>{page.name}</HeadlineSmallCaps>
        <div css={{ display: `flex`, flexWrap: `wrap` }}>
          {sectionsToRender.length === 1 && (
            <Single section={sectionsToRender[0]} />
          )}
          {sectionsToRender.length > 1 &&
            sectionsToRender.map((section, i) => (
              <Column section={section} index={i} key={i} />
            ))}
        </div>
      </div>
    </RevealOnScroll>
  )
}

GuidesSection.propTypes = {
  page: PropTypes.object.isRequired,
}

export default GuidesSection
